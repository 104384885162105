import { affiliateOfferZones } from "@lib/shared/config";
import React from "react";
import { ListingItem } from "src/lib/shared/types";
import Offer from "../offer";
import styles from "./mainListing.module.scss";
interface Props {
    listing: ListingItem[] | undefined;
    isComparison?: boolean;
}

const MainListing: React.FC<Props> = ({ listing, isComparison }) => {
    return (
        <div className={`md:px-4 pb-10 ${styles.listing}`}>
            {listing &&
                listing.map((item, idx) => {
                    return (
                        <Offer
                            zone={
                                isComparison
                                    ? affiliateOfferZones.COMPARISON_PAGE
                                    : affiliateOfferZones.HOME_PAGE
                            }
                            id={idx}
                            key={idx}
                            offer={item}
                        />
                    );
                })}
        </div>
    );
};
export default MainListing;
