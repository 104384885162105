import React from "react";
import styles from "./offer.module.scss";
import { ListingItem } from "src/lib/shared/types";
import Image from "next/image";
import AffiliateOfferUrl from "@components/shared/affiliateOfferUrl";
import { AffiliateOfferZone } from "@lib/shared/config";
interface Props {
    id: number;
    offer: ListingItem;
    zone: AffiliateOfferZone;
}

const Offer: React.FC<Props> = ({ id, offer, zone }) => {
    const {
        image,
        logo,
        description,
        features,
        attributes,
        price,
        oldPrice,
        discount,
        currency,
        title,
    } = offer;

    const wantedAttributes = [
        "security level",
        "free version",
        "money back guarantee",
        "firewall",
        "included vpn",
    ];

    return (
        <AffiliateOfferUrl
            zone={zone}
            className={`flex relative p-4 lg:p-8 border-b-2 md:border-2 flex-col justify-between lg:my-6 ${styles["listing-item"]}`}
            offer={offer}
            target="_blank"
        >
            <div
                className={`hidden lg:flex absolute top-0 left-0 w-12 h-12 ${styles.gray} items-center font-bold text-2xl justify-center`}
            >
                <p>{id + 1}</p>
            </div>
            {discount && (
                <div
                    className={`${styles.corner} px-6 py-2 lg:p-0 absolute bottom-4 lg:right-0 lg:top-0 font-bold`}
                >
                    <p className="absolute text-sm">-{discount}</p>
                </div>
            )}
            <div className="flex flex-col lg:flex-row lg:mb-5 top">
                <div className="flex flex-col-reverse justify-center lg:items-center lg:flex-row lg:p-3 w-4/12  mr-8">
                    <div className="max-w-200px hidden mr-6 lg:block w-28">
                        {image && (
                            <Image
                                src={`${image?.fullPath}`}
                                width={112}
                                height={170}
                                alt={`${title} image`}
                            />
                        )}
                    </div>
                    {logo && (
                        <img
                            className="max-w-200px lg:mb-0 mb-2"
                            src={`${logo?.fullPath}`}
                            alt={`${title} logo`}
                        />
                    )}
                </div>
                <div className="flex flex-col lg:w-6/12">
                    <h1 className="font-bold mb-6 text-sm">{description}</h1>
                    <ul className=" flex-col  hidden lg:flex text-sm">
                        {features.map((item, idx) => {
                            return (
                                <li
                                    key={idx}
                                    className="flex items-center mb-2"
                                >
                                    <img
                                        src="/assets/tick.svg"
                                        className="w-4 h-3 mr-2"
                                        alt="check"
                                    />
                                    <p>{item}</p>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="flex lg:flex-col justify-end lg:justify-center  lg:ml-auto relative last">
                    <div className="flex flex-col-reverse items-center justify-center lg:flex-row  mr-1">
                        {oldPrice && (
                            <p
                                className={`text-sm lg:text-base ml-2 ${styles.strike}`}
                            >
                                {currency}
                                {oldPrice}
                            </p>
                        )}
                        {price && (
                            <p
                                className={`${styles.red} ml-2 font-bold lg:text-2xl`}
                            >
                                {currency}
                                {price}
                            </p>
                        )}
                    </div>
                    <button
                        className={`${styles.button} flex items-center justify-center lg:text-xl lg:py-2  w-32 lg:w-40 rounded-md font-bold relative`}
                    >
                        Visit Site
                        <img
                            className="w-4 ml-2 lg:w-5"
                            src="/assets/double-arrow.svg"
                            alt=""
                        />
                    </button>
                </div>
            </div>
            <div className="flex text-sm">
                <button
                    className={`${styles["button-right"]} items-center py-2 px-4 rounded hidden relative lg:flex justify-center w-4/12 mr-6`}
                >
                    <p>View Offer</p>
                    <img
                        className="w-4"
                        src="/assets/right-arrow2.svg"
                        alt=""
                    />
                </button>
                <ul
                    className={`${styles.yellow} justify-around text-xs hidden py-3 lg:flex rounded-md w-8/12 font-poppins`}
                >
                    {Object.keys(attributes).map((attributeKey, idx) => {
                        return (
                            wantedAttributes.includes(
                                attributeKey?.toLocaleLowerCase(),
                            ) && (
                                <li key={idx}>
                                    <div className="flex flex-row">
                                        <p>
                                            {attributeKey}{" "}
                                            <span className="font-bold">
                                                {attributes[attributeKey]
                                                    ?.toLowerCase()
                                                    ?.includes("true")
                                                    ? "Available"
                                                    : attributes[attributeKey]}
                                            </span>
                                        </p>
                                    </div>
                                </li>
                            )
                        );
                    })}
                </ul>
            </div>
        </AffiliateOfferUrl>
    );
};
export default Offer;
